<template>
  <div>
    <Error404 v-if="error404occured" />

    <main v-else id="content" role="main" class="">
      <!-- ========== Breadcrumb ========== -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-md-3 mb-0">
            <li class="breadcrumb-item">
              <!-- <a href="/resource-library"> -->
              <router-link :to="{ path: '/resource-library' }">
                Resource Library
              </router-link>
              <!-- </a> -->
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ resource.title }}</li>
          </ol>
        </nav>
      </div>
      <!-- ========== End Breadcrumb ========== -->
      <!-- SVG Top Shape -->
      <figure class="mt-lg-5">
        <svg
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="100%"
          height="64px"
          viewBox="0 0 1921 273"
          style="margin-bottom: -8px; enable-background:new 0 0 1921 273;"
          xml:space="preserve"
        >
          <polygon fill="#f9fbff" points="0,273 1921,273 1921,0 " />
        </svg>
      </figure>
      <!-- End SVG Top Shape -->
      <div class="bg-light position-relative">
        <div class="container space-bottom-2">
          <div class="row">
            <div class="col-lg-6 col-12 pr-lg-4 mt-3 mb-3 mb-lg-0">
              <div class="h3"><span class="badge badge-soft-primary badge-pill mb-3 p-2">On-Demand Webinar</span></div>
              <h2 class="mb-4">{{ resource.title }}</h2>
              <p>{{ resource.description }}</p>
            </div>
            <div class="col-lg-6 col-12 mt-lg-n11">
              <div class="card" data-aos="fade-up" data-aos-delay="200">
                <div class="card-body p-md-7 p-lg-6">
                  <div v-if="resource.more_card" class="text-center">
                    <h3>Ready to watch the webinar?</h3>
                    <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
                    <p>This resource is now available on <strong>OpenAPIHub</strong>—our one-stop API Community Platform.<br>Please log in to the platform, or register as an OpenAPIHub member for FREE to watch the resource now!</p>
                    <a id="ga-BW-CC15-01-03" :href="resource.more_card[0].resource_url" target="_blank" class="btn btn-primary transition-3d-hover">Watch on OpenAPIHub</a>
                  </div>
                  <!-- <div class="form-submit">
                    <div class="text-center">
                      <h3>Ready to watch the webinar?</h3>
                    </div>
                    <form ref="webinarRecordForm" />
                    <div v-if="isSubmitted" class="modal-submitted-content">
                      <div class="text-center my-4">
                        <span>You may find the webinar resources below:</span>
                      </div>
                      <div v-if="resource.more_card[0].video_url" class="w-lg-90 mx-auto mb-5">
                        <h5>Webinar</h5>
                        <a class="card card-frame p-3" :href="resource.more_card[0].video_url" target="_blank">
                          <div class="d-flex align-items-center">
                            <figure class="max-w-6rem w-100">
                              <img class="img-fluid" src="@/assets/svg/icons/icon-41.svg">
                            </figure>
                            <div class="pl-3 font-weight-bold">Watch Webinar Now</div>
                            <div class="ml-auto"><i class="fas fa-chevron-right" /></div>
                          </div>
                        </a>
                      </div>
                      <div v-if="resource.more_card[0].ppt_url" class="w-lg-90 mx-auto mb-5">
                        <h5>Presentation Slide</h5>
                        <a id="ga-webinar-download-slide-button" class="btn btn-primary btn-block mb-1" :href="`/document/webinar/${resource.more_card[0].ppt_url}`" download>
                          <i class="fas fa-download mr-1" /> Download Presentation Slide
                        </a>
                        <span class="mt-2 font-size-1 d-block">* Unlock the file with password: <span class="text-dark font-weight-bold h5">benovelty</span>
                        </span>
                      </div>
                      <img class="img-fluid w-50 mx-auto mb-3 float-right" :src="require('@/assets/svg/illustrations/replay-video.svg')">
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container space-2">
        <div class="w-lg-80 mx-auto">
          <div v-if="resource.organiser" class="mb-8">
            <h3>Organisers</h3>
            <div class="row justify-content-lg-center">
              <div v-for="item in resource.organiser" :key="item.company" class="col-6 col-md-3 text-center">
                <img class="mb-1" style="max-width:8rem" :src="require('@/assets/img/resource-library/' + item.image_url)">
              </div>
            </div>
          </div>

          <div v-if="resource.about" class="mb-8">
            <h3>About this {{ resource.category }}</h3>
            <p v-for="item in resource.about" :key="item">{{ item }}</p>
            <div v-if="resource.about_special" v-html="resource.about_special" />
          </div>

          <div v-if="resource.agenda" class="mb-8">
            <h3>Agenda</h3>
            <ul>
              <li v-for="item in resource.agenda" :key="item" class="mb-3">
                <span>{{ item }}</span>
              </li>
            </ul>
          </div>

          <div v-if="resource.host" class="mb-8">
            <h3>Host</h3>
            <ul>
              <li v-for="item in resource.host" :key="item.name" class="mb-4">
                <span class="d-block">{{ item.name }}</span>
                <span class="d-block text-body font-size-1">{{ item.job_title }}</span>
                <div class="border-left border-3 pl-4 mt-2">
                  <p>{{ item.bio }}</p>
                </div>
              </li>
            </ul>
          </div>

          <div v-if="resource.moderator" class="mb-8">
            <h3>Moderator</h3>
            <ul>
              <li v-for="item in resource.moderator" :key="item.name" class="mb-4">
                <span class="d-block">{{ item.name }}</span>
                <span class="d-block text-body font-size-1">{{ item.job_title }}</span>
                <div class="border-left border-3 pl-4 mt-2">
                  <p>{{ item.bio }}</p>
                </div>
              </li>
            </ul>
          </div>

          <div v-if="resource.speaker" class="mb-8">
            <h3>Speakers</h3>
            <ul>
              <li v-for="item in resource.speaker" :key="item.name" class="mb-4">
                <span class="d-block">{{ item.name }}</span>
                <span class="d-block text-body font-size-1">{{ item.job_title }}</span>
                <div class="border-left border-3 pl-4 mt-2">
                  <p>{{ item.bio }}</p>
                </div>
              </li>
            </ul>
          </div>

          <div v-if="resource.organiser_about" class="mb-8">
            <h3>About the Organisers</h3>
            <ul>
              <div v-if="resource.organiser_about" v-html="resource.organiser_about" />
            </ul>
          </div>
        </div>
      </div>

      <div v-if="resource.gallery" class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-career-hero.svg') + ')'}">
        <div class="container space-2">
          <h3>Gallery</h3>
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-2"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n3 d-none d-lg-block\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n3 d-none d-lg-block\&quot;></span>&quot;,
                &quot;infinite&quot;: true,
                &quot;autoplay&quot;: true,
                &quot;autoplaySpeed&quot;: 6000,
                &quot;dots&quot;: true,
                &quot;dotsClass&quot;: &quot;slick-pagination d-flex justify-content-center d-lg-none mt-3&quot;,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div v-for="item in resource.gallery" :key="item.image_url" class="js-slide">
              <img class="img-fluid" :src="require('@/assets/img/resource-library/webinars/gallery/' + item.image_url)">
              <!-- <div class="card bg-img-hero min-h-300rem" :style="{'background-image': 'url(' + require('@/assets/img/resource-library/webinars/banners/'+item.image_url) + ')'}" /> -->
            </div>
          </div>
        </div>
      </div>

    </main>
  </div>
</template>

<script>
const $ = require('jquery')
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.slick-carousel.js'
import '../../../assets/vendor/slick-carousel/slick/slick.js'
import HSStickyBlock from '@/assets/vendor/hs-sticky-block/src/js/hs-sticky-block'
import Error404 from '@/views/error404/index'
import 'bootstrap'
import { mapGetters } from 'vuex'
export default {
  name: 'ResourceWebinar',
  components: {
    Error404
  },
  data() {
    return {
      resource: {},
      error404occured: false,
      isSubmitted: false
    }
  },
  computed: {
    ...mapGetters([
      'resources'
    ])
  },
  created() {
    const title = this.$route.params && this.$route.params.resource
    const resource_list = this.$store.getters.resources.resource_list
    // for (let i = 0; i < resource_list.length; i++) {
    //   if (resource_list[i].url.substring(resource_list[i].url.indexOf('/') + 1, resource_list[i].url.length) === title) {
    //     this.resource = resource_list[i]
    //   }
    // }

    // Check whether have the info of the parms
    let i = 0
    let found = false
    while ((i < resource_list.length) && (found === false)) {
      if (resource_list[i].url.substring(resource_list[i].url.indexOf('/') + 1, resource_list[i].url.length) === title) {
        this.resource = resource_list[i]
        found = true
      } else {
        i = i + 1
      }
    }
    // If not, show error 404
    if (found === false) {
      this.error404occured = true
    }
  },
  mounted() {
    $('.js-sticky-block').each(function() {
      new HSStickyBlock($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    // this.loadScriptWebinarRecordForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "f5f65673-339f-4bb2-b91f-f4de1ce63e1b"
    //     });
    //   `
    //   this.$refs.webinarRecordForm.appendChild(secondScript)
    // })
    // window.addEventListener('message', event => {
    //   if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    //     this.isSubmitted = true
    //   }
    // })
  },
  // methods: {
  //   loadScriptWebinarRecordForm(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.webinarRecordForm.appendChild(script)
  //   }
  // },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: this.resource.title + ' | ' + this.resource.category + ' | %s',
      link: [
        // { rel: 'canonical', href: `https://www.benovelty.com/events/${this.resource.url.split('/')[1]}` }
        { rel: 'canonical', href: this.resource.canonical }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: this.resource.title + ' | ' + this.resource.category + ' | beNovelty' },
        { property: 'og:description', content: this.resource.meta_content },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails' + this.resource.url.substring(this.resource.url.indexOf('/'), this.resource.url.length) + '.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/' + this.resource.url },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
